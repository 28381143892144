import { MODULES } from '@/constants';
import AuthService from '@/modules/auth/services/auth-service';
import { Store } from 'vuex';
import VueRouter from 'vue-router';

export const getAuthToken = (params: {
    code: string;
    authService: AuthService;
    store: Store<unknown>;
    router: VueRouter;
    routeName: string;
}): void => {
    const onFinish = () => params.router.push({ name: params.routeName });

    params.authService
        .exchangeCodeForAuthToken(params.code)
        .then((r: Auth.UserTokens) => {
            params.store.commit(
                `${MODULES.AUTH.STORE.NAMESPACE.AUTH}/${MODULES.AUTH.STORE.MUTATION.SET_USER_TOKENS}`,
                r
            );
            onFinish();
        })
        .catch((e) => {
            console.error('Error while exchanging code for auth token', e);
            onFinish();
        });
};
