




import Vue from 'vue';
import { Component } from 'vue-property-decorator';
import config from '@/config';
import AuthService from '@/modules/auth/services/auth-service';
import { getAuthToken } from '@/modules/auth/effects/get-auth-token';
import { MODULES } from '@/constants';

@Component
export default class GetAuthToken extends Vue {
    mounted(): void {
        getAuthToken({
            code: window.location.search.split('code=')[1],
            authService: new AuthService(config.auth),
            store: this.$store,
            router: this.$router,
            routeName: MODULES.HOMEPAGE.ROUTE.NAME.HOME,
        });
    }
}
